<template>
  <div style="padding: 20px">
    <div>
      <a-form layout="horizontal">
        <div>
          <a-row style="display: flex;flex-wrap: wrap;">
            <a-col  style="width:33%;max-width:400px;">
              <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-row>
                  <a-range-picker :value="dateValue" :ranges="{
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
               '当月': [moment().startOf('month'), moment().endOf('month')],
  '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }" style="width: 100%;" @change="dateChange" />
                </a-row>
              </a-form-item>
            </a-col>

            <a-col style="max-width:400px;">
              <span class="btn-box" style="margin-top: 4px; display: inline-block;">
                  <a-button type="primary" @click="search">查询</a-button>
                  <a-button style="margin-left: 8px" @click="reset(1)">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </div>
      </a-form>
    </div>

    <div>
      <a-button type="primary" @click="addForm">申请活动</a-button>
      <div style="margin: 10px;font-size: 15px;">显示第 {{ total>0 ? 1 : 0 }} 至 {{ queryData.size > total ?  total : queryData.size }} 项结果，共 {{ total }} 项</div>
    </div>
    <a-table :columns="activityColumns" :dataSource="dataSource" bordered :loading="tabLoading" :pagination="false" :scroll="{ x:1500}"
               class="components-table-demo-nested">
      <template slot="createTime" slot-scope="record">
        <span v-if="record">{{ formatDateMin(record) }}</span>
        <span v-else>-</span>
      </template>
      <template slot="startTime" slot-scope="record">
        <span v-if="record">{{ formatDateMin(record) }}</span>
        <span v-else>-</span>
      </template>
      <template slot="endTime" slot-scope="record">
        <span v-if="record">{{ formatDateMin(record) }}</span>
        <span v-else>-</span>
      </template>

      <template slot="showTime" slot-scope="record">
        <span v-if="record">{{ formatDateMin(record) }}</span>
        <span v-else>-</span>
      </template>
      <template slot="closeTime" slot-scope="record">
        <span v-if="record">{{ formatDateMin(record) }}</span>
        <span v-else>-</span>
      </template>

        <template slot="action" slot-scope="record">
          <div>
            <span v-if="record.acStatus != 3">
              <a v-if="record.allocationStatus == 1 && record.status == 5" @click="details(record.id,2)"  style="margin-right: 5px;">编辑</a>
              <a v-if="record.allocationStatus == 1 && record.status != 5" @click="detailsRecord(record.id)" style="margin-right: 5px;">撤回</a>
            </span>
            <a @click="details(record.id,3)" style="margin-right: 10px;">详情</a>
          </div>
          <a style="margin-right: 10px;" @click="Printing(record.id, )" >活动申请单</a>
<!--          <div v-if="record.allocationStatus == 3">-->
<!--            <a @click="rankDetail(record.activityId,2)" style="margin-right: 5px;">排行榜</a>-->
<!--            <a @click="dailyDetail(record.activityId,1)" style="margin-right: 5px;">每日明细</a>-->
<!--          </div>-->
        </template>
      </a-table>
      <div class="page-box">
        <a-pagination :total="total" :current="queryData.page + 1" :pageSize="queryData.size" @change="handlePageChange" />
      </div>

    <AddActivityFrom @getFormData="getFormData" ref="addFromFef"></AddActivityFrom>
    <AddActivityFrom @getFormData="getFormData" ref="editFromFef"></AddActivityFrom>



    <!--  明细  -->
    <a-modal title="每日明细" :visible="dailyShow" @cancel="dailyShow = false" :footer="null" :maskClosable="false" :width="1100">
      <div style="margin: 0px 0 20px;">
        <span>日期：</span>
        <a-date-picker :default-value="moment(dailyDateValue, 'YYYY/MM/DD')" v-model="dailyDateValue" :value="dailyDateValue" @change="dailyChange" placeholder="请选择日期" />
        <a-button type="primary" @click="exportDaily(0)" style="margin-left: 20px;">导出</a-button>
      </div>
      <a-table :columns="dailyColumns"  :dataSource="dailyDataSource" bordered :pagination="false" :scroll="{ x:1500}">
        <template slot="modifyTime" slot-scope="record">
          <span v-if="record">{{ formatDateMin(record) }}</span>
          <span v-else></span>
        </template>
        <template slot="day" slot-scope="record">
          {{ formatDate(record) }}
        </template>
      </a-table>
      <div class="page-box">
        <a-pagination :total="altotal" :current="dailyQuery.page + 1" :pageSize="dailyQuery.size" @change="handlePageChangeDaily" />
      </div>
    </a-modal>

<!--    排行榜-->
    <a-modal title="排行榜" :visible="rankShow" @cancel="rankShow = false" :footer="null" :maskClosable="false" :width="1100">
      <div style="margin: 0px 0 20px;">
        <a-button type="primary" @click="exportDaily(1)" style="margin-left: 20px;">导出</a-button>
      </div>
      <a-table :columns="chartColumns"  :dataSource="rankDataSource" bordered :pagination="false" :scroll="{ x:1500}">
        <template slot="modifyTime" slot-scope="record">
          <span v-if="record">{{ formatDateMin(record) }}</span>
          <span v-else></span>
        </template>
      </a-table>
      <div class="page-box">
        <a-pagination :total="rankTotal" :current="rankQuery.page + 1" :pageSize="rankQuery.size" @change="handlePageChangeRank" />
      </div>
    </a-modal>

<!--    rankShow-->


<!--    打印-->
    <div v-if="printVisit">
      <a-modal :visible="printVisit" :footer="null" width="80%" @cancel="printVisit=false">
        <div class="print-main" id="print">
          <h2 class="print-title">线上活动申请单</h2>
          <ul class="print-top">
            <li>
              创建时间：<span>{{ formatDateMin(printData.createTime) }}</span>
            </li>
            <li>
              客户名称：<span>{{ printData.customerName }}</span>
            </li>
            <li>
              卖方主体：<span>{{ printData.sellerName }}</span>
            </li>
            <li>
              活动主题：<span>{{ printData.sfaActivityList[0].theme }}</span>
            </li>
            <li>
              活动销售金额：<span>{{ (printData.sfaActivityList[0].eventSalesAmount).toFixed(2) }}</span>
            </li>
            <li>
              奖品预算金额：<span>{{ (printData.sfaActivityList[0].prizeBudgetAmount).toFixed(2) }}</span>
            </li>
          </ul>
          <div  class="print-table">
            <a-card  title="线上活动" :bordered="false" style="padding: 0">
              <div style="margin-top: 20px;">
                <a-table :columns="printColumns" bordered :dataSource="printData.sfaActivityList" :pagination="false" style="padding: 0">
                  <template slot="distributorCode" slot-scope="record">
                    <span v-if="record == '20220601001'">优加惠品</span>
                    <span v-if="record == '20221212001'">好乐滋</span>
                  </template>

                  <template slot="startTime" slot-scope="record,text">
                    <div>{{ formatDateMin(text.startTime) }}</div>
                    <div>{{ formatDateMin(text.endTime) }}</div>
                  </template>

                  <template slot="showTime" slot-scope="record,text">
                    <div>{{ formatDateMin(text.showTime) }}</div>
                    <div>{{ formatDateMin(text.closeTime) }}</div>
                  </template>


                  <span slot="incrementAmount" style="font-weight:700;">
                增值服务抵扣
              </span>

                  <span slot="amountRecharge" slot-scope="record">
                  {{record.amountRecharge}}<span v-if="record.gearPositionName">({{record.gearPosition == 9 ? '定制档' : '通用档'}})</span>
              </span>



                  <template slot="isDelay" slot-scope="record,text">
                    <span v-if="text.isDelay == 1">允许延期</span>
                    <span v-if="text.isDelay == 2">不允许延期</span>
                  </template>

                </a-table>

                <div style="display: flex;margin-top: 20px; padding: 0 20px;box-sizing: border-box;" class="rule-text">
                  <div style="flex: 1;display: flex;margin-right: 20px;align-items: center">
                    <div >
                      <span>活动规则:</span>
                    </div>
                    <div >
                      <span v-if="printData.sfaActivityList[0].rule">{{ printData.sfaActivityList[0].rule }}</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </div>
              </div>
            </a-card>
          </div>

          <div class="print-btm">
            <p>客户经理：{{printData.salespersonName}}</p>
            <p>销售主管：</p>
            <p>销售总监：</p>
            <!-- <p>:</p> -->
            <p>线上运营:</p>

          </div>
        </div>

        <div class="print-btn no-print">
          <a-button type="primary" style="margin-right: 10px;" v-print="printObj">打印预览</a-button>
          <a-button @click="printVisit = false">关闭</a-button>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import {activityColumns,dailyColumns,chartColumns} from './components/Columns.js';
import {request} from "@/utils/request";
import moment from 'moment';
import 'moment/locale/zh-cn';
import AddActivityFrom from "@/pages/sfa_workOrder/activityComponent/addActivityFrom.vue";
  export default {
    components:{AddActivityFrom},
    data(){
      return {
        rankTotal:0,
        printData:null,
        moment,
        printVisit:false,//打印弹窗
        dataSource:[],
        activityColumns,
        dailyColumns,
        chartColumns,
        form:{},
        queryData:{
          page:0,
          size:10
        },
        rankQuery:{
          page:0,
          size:10
        },
        dailyQuery:{
          page:0,
          size:10,
          day:'',
        },
        total:0,
        currentPage:0,
        dateValue:'',
        applyType:undefined,
        detailsObj:{},
        dailyShow:false,
        rankShow:false,
        dailyDateValue:'',
        tabloading:false,
        rankDataSource:[],
        dailyDataSource:[],
        altotal:0,
        dailyDate:'',
        printColumns:[
          {
            title: '分销商',
            dataIndex: 'distributorCode',
            scopedSlots: {customRender: 'distributorCode'},
            align: 'center',
            // width:110
          },
          {
            title: '活动类型',
            dataIndex: 'typeName',
            align: 'center',
          },
          {
            title: '奖品类型',
            dataIndex: 'prizeFormatName',
            align: 'center',
            // width:150
          },
          {
            title: '活动时间',
            dataIndex: 'startTime',
            align: 'center',
            scopedSlots: {customRender: 'startTime'},
            width:220
          },
          {
            title: '用户端展示时间',
            dataIndex: 'showTime',
            align: 'center',
            scopedSlots: {customRender: 'showTime'},
            width:225
          },
          {
            title: '是否需要开票',
            dataIndex: 'invoiceName',
            scopedSlots: {customRender: 'invoiceName'},
            align: 'center',
            // width:100
          },
        ],
        printObj: {
          id: "print", //要打印的id名 无#号
          extraCss: "", // 打印可引入外部的一个 css 文件
          popTitle: "&nbsp;", //打印的页眉标题，默认浏览器标题 空字符串时显示undefined 使用html语言
          extraHead: "", //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
          preview: "", // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
          previewTitle: "", // 打印预览的标题（开启预览模式后出现）,
          previewPrintBtnLabel: "", // 打印预览的标题的下方按钮文本，点击可进入打印（开启预览模式后出现）
          zIndex: "", // 预览的窗口的z-index，默认是 20002（此值要高一些，这涉及到预览模式是否显示在最上面）
          previewBeforeOpenCallback() {}, //预览窗口打开之前的callback（开启预览模式调用）
          previewOpenCallback() {}, // 预览窗口打开之后的callback（开启预览模式调用）
          beforeOpenCallback() {}, // 开启打印前的回调事件
          openCallback() {}, // 调用打印之后的回调事件
          closeCallback() {}, //关闭打印的回调事件（无法确定点击的是确认还是取消）
          url: "",
          standard: "",
        },
      }
    },
    mounted() {
      this.getData();
      let today = new Date(); // 创建一个表示今天日期的Date对象
      let year = today.getFullYear(); // 获取当前年份
      let month = today.getMonth() + 1; // getMonth() 返回的是0到11，所以需要加1
      let day = today.getDate(); // 获取当前日期
      let formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
      this.dailyQuery.day = formattedDate;
      this.dailyDateValue = formattedDate;

    },
    methods:{
      // 打印
      Printing(id){
        this.printVisit = true;
        request(process.env.VUE_APP_API_BASE_URL+'sfa/saledocket/ac/'+id,'get').then(res=>{
          this.printData = res.data.data;
        })
      },
      search(){
        this.queryData.page = 0;
        this.getData();
      },
      rankDetail(id){
        if(id) {
          this.activitiesId = id;
          this.rankQuery.activitiesId = id;
        }
        // this.activitiesId = id;
        this.rankShow = true;
        // this.rankQuery.activitiesId = id;
        request(process.env.VUE_APP_API_BASE_URL+'sfa/ac/walk/rank/list','post',this.rankQuery).then(res=>{
          if(res.data.code == 200) {
            this.rankDataSource = res.data.data.data;
            this.rankQuery.page = res.data.data.page;
            this.rankQuery.size = res.data.data.size;
            this.rankTotal = res.data.data.total;
          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      dailyDetail(id){
        if(id) {
          this.activitiesId = id;
          this.dailyQuery.activitiesId = id;
        }
        this.dailyShow = true;
        request(process.env.VUE_APP_API_BASE_URL+'sfa/ac/walk/rank/dayList','post',this.dailyQuery).then(res=>{
          if(res.data.code == 200) {
            this.dailyDataSource = res.data.data.data;
            this.dailyQuery.page = res.data.data.page;
            this.dailyQuery.size = res.data.data.size;
            this.altotal = res.data.data.total;
          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      dailyChange(date,dateString) {
        this.dailyDateValue = date;
        this.dailyQuery.day = dateString;
        this.dailyDetail()
      },
      exportDaily(type) {
        let url = 'sfa/ac/walk/rank/exportList'
        let params = {remarks:'导出',queryRankParam:{activitiesId:this.activitiesId}};
        if(type == 0) {
          url = 'sfa/ac/walk/rank/exportDayList'
          params = {remarks:'导出',viewWalkDayRankParam:{activitiesId:this.activitiesId,day:this.dailyQuery.day}};
        }
        request(process.env.VUE_APP_API_BASE_URL + url, 'post', params).then(res => {
          if (res.data.code == 200) {
            this.$message.success('导出成功，请前往个人中心-导出任务列表查看！', 3)
          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      details(id,type){
        request(process.env.VUE_APP_API_BASE_URL+'sfa/saledocket/ac/'+id,'get').then(res=>{
          if(res.data.code == 200) {
            this.detailsObj = res.data.data;
            let obj = res.data.data;
            obj.sfaActivityList[0].customerType = obj.customerType;
            obj.sfaActivityList[0].customerId = obj.customerId;
            obj.sfaActivityList[0].sellerId = obj.sellerId;
            obj.sfaActivityList[0].customerDeptId = obj.customerDeptId;
            setTimeout(()=>{
              this.$refs.editFromFef.applyType = type; // 2编辑 3详情
              this.applyType = type;
              this.$refs.editFromFef.visible = true;
              this.$refs.editFromFef.form = obj.sfaActivityList[0];
              this.$refs.editFromFef.customerType = obj.customerType;
              this.$refs.editFromFef.customerDeptId = obj.customerDeptId;
              this.$refs.editFromFef.getInitData()
              this.$refs.editFromFef.getDeptList()
            },0)

          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      // 表单返回数据保存
      getFormData(data){
        let params = {
          customerId:data.customerId,
          status:4,
          sellerId:data.sellerId,
          customerType:data.customerType,
          customerDeptId:data.customerDeptId,
          sfaActivityList:[data]
        }
        if(this.applyType == 1) {
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/ac/save','post',params).then(res=>{
            if(res.data.code == 200) {
              this.$message.success('操作成功！')
              this.getData();
            } else {
              this.$message.error(res.data.message)
            }
          })
        } else {
          params.id = this.detailsObj.id;
          delete params.sfaActivityList[0].id
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/ac/update','post',params).then(res=>{
            if(res.data.code == 200) {
              this.$message.success('操作成功！')
              this.detailsObj = null;
              this.getData();
            } else {
              this.$message.error(res.data.message)
            }
          })
        }
      },
      // 申请表单
      addForm(){
        this.$refs.addFromFef.visible = true;
        this.$refs.addFromFef.applyType = 1; // 新增
        this.applyType = 1;
        this.$refs.addFromFef.resetForm();
      },
      reset(i){
        if(i == 1) {
          this.queryData = {
            page:0,
            size:10
          }
          this.dateValue = ''
        }
        this.tabLoading = true;
        this.getData()
      },
      // 选择日期
      dateChange(date, dateString) {
        this.queryData.submitStartTime = dateString[0].toString();
        this.queryData.submitEndTime = dateString[1].toString();
        this.dateValue = date;
      },
      handlePageChange(page) {
        this.queryData.page = page - 1;
        this.currentPage = page;
        this.getData()
      },
      handlePageChangeRank(page) {
        this.rankQuery.page = page - 1;
        this.currentPage = page;
        this.rankDetail()
      },
      handlePageChangeDaily(page) {
        this.rankQuery.page = page - 1;
        this.currentPage = page;
        this.dailyDetail()
      },
      detailsRecord(id){
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/ac/updateStatus','post',{id,status:5}).then(res=>{
          if(res.data.code == 200){
            this.$message.success('操作成功！')
          } else {
            this.$message.error(res.data.message)
          }
          this.getData()
        })
      },
      // 获取列表
      getData() {
        this.tabLoading = true;
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/ac/list','post',this.queryData).then(res=>{
          this.tabLoading = false;
          if (res.data.code == 200) {
            this.dataSource = res.data.data.data;
            this.queryData.page = res.data.data.page;
            this.queryData.size = res.data.data.size;
            this.total = res.data.data.total;
          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      // 格式化年月日
      formatDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
        return result
      },
      formatDateMin(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
        return result;
      },
    }
  }
</script>

<style>

.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}

.print-main ::v-deep .ant-card-body {
  padding:12px !important;
}
.print-main ::v-deep .ant-card-head {
  border: 0 !important;
  min-height:0  !important;
  padding:0 12px !important;
}
.print-main ::v-deep .ant-card-head-title {
  padding:0 !important;
}
.print-btn {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.print-title{
  text-align: center;
  font-size: 24px;
}
.print-table {
  margin-bottom:30px;
}
 ::v-deep .ant-card-body {
  padding: 0 !important;
}
.print-top {
  display: flex;
  justify-content: center;
  color: #000;
  font-weight: 500;
  flex-wrap: wrap;
  padding:0 12px;
  margin-top: 30px;
}
.print-top li {
  width: 33.33%;
  margin-bottom:16px;
  font-size: 16px;
}
.print-top span {
  font-weight: 500;
  color: #000;
}

.print-main {
  width:100%;
  margin:auto;
  height:100%;
}
.print-btm {
  display:flex;
  padding:10px;
  font-size: 16px;
  margin-top:40px;
  height:100px;
  font-weight: 500;
  color: #000;
  width: 100%;
}
.print-btm p {
  flex:1;
  color:#000;
}
.print-table ::v-deep .ant-card {
  font-size:16px;
  color:#000 !important;
  font-weight: 500;
}
.print-table ::v-deep .ant-table {
  font-size:16px;
  color:#000 !important;
  font-weight: 500;
}
.print-main ::v-deep  .ant-card-head {
  border: 0 !important;
  min-height:0  !important;
  padding:0 12px !important;
}

/* 自定义表格线颜色 */
.print-main ::v-deep .ant-table-tbody > tr > td {
  border-color: #333; /* 设置表格单元格的边框颜色 */
}

/* 可选：设置表格头部线颜色 */
.print-main ::v-deep .ant-table-thead > tr > th {
  border-color: #333; /* 设置表格头部单元格的边框颜色 */
}

/* 可选：设置表格外边框颜色 */
.print-main ::v-deep .ant-table {
  border-color: #000; /* 设置表格的外边框颜色 */
}
.print-main ::v-deep .ant-table-bordered .ant-table-body > table {
  border-color: #333; /* 设置表格的外边框颜色 */
}
.print-main table{
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-spacing: 0;

}
.print-main table {
  width:100%;
}
.print-main  tr {
  width: 150px;
  height:60px;
  border-bottom: 1px solid #000;
  padding: 8px 10px;
  border-right: 1px solid #000;
  line-height: 20px;
  text-align: center;
}
.print-main  td {
  width: 150px;
  height:60px;
  border-bottom: 1px solid #000;
  padding: 8px 10px;
  border-right: 1px solid #000;
  line-height: 20px;
  text-align: center;
}
</style>
<!-- 打印样式表 -->
<style media="print">
/* 设置打印时的样式 */
@media print {
  .print-title {
    font-size: 26px;
  }
  .print-top li {
    font-size: 24px;
  }
  .print-top span{
    font-size: 24px;
  }
  .ant-table-body {
    font-size: 20px;
  }
  .ant-card-head {
    font-size: 20px;
  }
  .rule-text {
    font-size: 22px;
  }
  .print-btm {
    font-size: 22px;
  }
    /* 隐藏不需要打印的元素 */
  .no-print {
    display: none;
  }
  .print-main {
    zoom: 65%;
    page-break-after: always;
    padding: 30px 0;
  }

  .ant-table {
    /* page-break-inside: avoid; */
  }
  /* 隐藏日期和网址 */
  @page {
    margin-top: 8mm;
  }

}


</style>